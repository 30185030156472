import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import api from "../../axios/api";
// import Thumbnails from './Thumbnails';
import ProductSlider from "./ProductSlider";
import { useDispatch } from "react-redux";
import { addItem } from "../../store/slices";
import { toast } from "react-toastify";
import Header from "../Header";

import Footer from "../Footer";
import WriteReview from "./WriteReview";
import Loader from "../Loader";
import { percentageDisc } from "../../utils";
import WhatsAppButton from "../WhatsAppButton";

export default function ProductDetail() {
  
const [prodDetail, setProdDetail] = useState(null);

const { slug } = useParams();

const dispatch = useDispatch();

const [selectedOption, setSelectedOption] = useState(1);
const navigate = useNavigate();

  useEffect(() => {
    const ProductDetail = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_BASE_URL}products-details/${slug}`);
        setProdDetail(response?.data?.data?.product);
      }
      catch (error) {
        console.error("API error:", error);
    }
  };

  ProductDetail();

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });

}, [slug]);

const handleAddCart = (item,selectedQty) => {
  const newItem = {
    productimg: item.image,
    id: item.id,
    slug:item.slug,
    name: item.prd_name,
    qty: selectedQty,
    msr_unit: item.msr_unit,
    unit: item.prd_qty,
    price: item.discounted_price,
  };
  dispatch(addItem(newItem));
  toast.success("Item Added Successfully",{toastId: "1"});
  navigate('/cart')
};



const handleChange = (event) => {
  setSelectedOption(Number(event.target.value));
  
};


const gotoWhatsapp=(e)=>{
  e.preventDefault();
  window.open('https://wa.me/7009326717', '_blank');
}

return (
<div className="wrapper product_detail_wrapper">
  <div className="header_outer">
    <div className="sec_container">
      <Header />
    </div>
  </div>
  
      
    
{prodDetail ? 
  <div className="product_detail">
    <div className="sec_container">
      <div className="prod_inner">
        <div className="prod_top">
          <div className="prod_left">
            <ProductSlider prodDetail={prodDetail} />
            
          </div>
          <div className="prod_right">
            <div className="name">{prodDetail?.prd_name}</div>
            
            
            {parse(`<div className="prd_about_box">${prodDetail?.prd_desc}</div>`)}

            {prodDetail?.product_type === 'veg' && (
    <div id="vnv-container" className="a-section a-spacing-none vnv-container">   
        <div id="inner-container-veg" className="a-section a-spacing-none vnv-inner-container vnv-inner-container-veg"> 
            <div id="outer-veg" className="a-section vnv-outer"> 
                <div id="circle-veg" className="a-section vnv-circle vnv-circle-veg"> </div> 
            </div> 
        </div> 
        <div id="text-veg" className="a-section vnv-text"> This is a <b>Vegetarian</b> product. </div>    
    </div>
)}

{prodDetail?.product_type === 'non_veg' && (
    <div id="vnv-container" className="a-section a-spacing-none vnv-container">    
        <div id="inner-container-nveg" className="a-section a-spacing-none vnv-inner-container vnv-inner-container-nveg"> 
            <div id="outer-nveg" className="a-section vnv-outer"> 
                <div id="triangle-nveg" className="a-section vnv-triangle"> </div> 
            </div> 
        </div> 
        <div id="text-nveg" className="a-section vnv-text"> This is a <b>Non-vegetarian</b> product. </div>   
    </div>
)}

{prodDetail?.product_type === 'egg' && (
    <div id="vnv-container" className="a-section a-spacing-none vnv-container">   
        <div id="inner-container-egg" className="a-section a-spacing-none vnv-inner-container vnv-inner-container-egg"> 
            <div id="outer-egg" className="a-section vnv-outer"> 
                <div id="circle-egg" className="a-section vnv-circle vnv-circle-egg"> </div> 
            </div> 
        </div> 
        <div id="text-egg" className="a-section vnv-text"> This product contains <b>Egg.</b>  </div>    
    </div>
)}

            
            <div className="price_outer">
              <div className="disc_price">Rs. {prodDetail?.price}</div>
              <div className="price" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                Rs. {prodDetail?.discounted_price} <span style={{fontSize:'12px',color:'green',fontWeight:'500'}}>({percentageDisc(prodDetail?.price,prodDetail?.discounted_price)}% off)</span>
                </div>
            </div>

            <div className="qty"  style={{marginBottom:'20px'}}>
              <span>Select Quantity : </span>
              <select name="" id="" value={selectedOption} onChange={handleChange}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>

            {prodDetail?.is_hot===0 ?
                          <span className="no_product" onClick={(e)=>gotoWhatsapp(e)}>This product is not sold online. For more information 
                          whatsapp us! 
                          <WhatsAppButton/>
                          </span>
                          :
            <span onClick={()=> handleAddCart(prodDetail,selectedOption)} className="add_cart go_cart">
              <i class="fa-solid fa-cart-shopping"></i> Go To Cart
            </span>
          }
          </div>
        </div>
        <div className="prod_bottom">
          <div className="write_a_review_outer">
            <div className="write_review_inner">
              <WriteReview prodDetail={prodDetail} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  :<Loader/>
  }
  <div className="footer_outer">
    <div className="sec_container">
      <Footer />
    </div>
  </div>
</div>
);
}