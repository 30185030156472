import React from 'react'
import SearchBar from './SearchBar'
import NavBar from './NavBar'
import { Link}  from 'react-router-dom'
import { useSelector } from 'react-redux'
import GoogleLoginPage from './GoogleLoginPage'


export default function Header() {
  const cartData = useSelector((store)=>store.cartData.items)


  const toggleBtn=()=>{
    document.body.classList.add('modal-open');
}
  
  return (
    
        <div className="header_inner">
          <div className="logo">
          
          <Link to="/">
            <img src="/assets/images/logo.svg" alt="" />
            </Link>
          </div>
          <div>
          
          <NavBar/>
          
          </div>
          
          <div className="cart_outer">



            <div className="desk_search">
          <SearchBar/>
          </div>
          
            <div className='your_acc'>
            <GoogleLoginPage/>
            <ul className='social_login'>
                <li>
                    <Link to="https://www.facebook.com/FidalgoGlutenFree" target='_blank'>
                    {/* <i class="fa-brands fa-facebook"></i> */}
                    <img src="/assets/images/facebook.png" alt="" style={{width:"18px"}}/>
                    </Link>
                </li>
                <li>
                    <Link to="https://www.instagram.com/fidalgo_glutenfree/" target='_blank'>
                    {/* <i class="fa-brands fa-instagram"></i> */}
                    <img src="/assets/images/instagram (1).png" alt="" style={{width:"19px"}}/>
                    </Link>
                </li>

                <li>
                    <Link to="https://www.youtube.com/@noglubyfidalgo-n3p" target='_blank'>
                    {/* <i class="fa-brands fa-youtube"></i> */}
                    <img src="/assets/images/youtube (1).png" alt="" style={{width:"23px"}}/>
                    </Link>
                </li>

             </ul>
            </div>
            <div className='cart_icon'>
              <Link to="/cart">
              <img src="/assets/images/cart_icon.svg" alt="" style={{width:"34px"}}/>
              <span>{cartData.length}</span>
              </Link>
            </div>
            <div className="mbl_btns">
        <div className="sec_container">
        <div className="header_outer">
          <div className="header_inner">
            
              {/* <SearchBar /> */}
            
          </div>
        </div>
        <div className="toggle_btn" onClick={toggleBtn}>
            <span><i className="fa-solid fa-bars-staggered"></i></span>
        </div>
        </div>
      </div>
          </div>
        </div>
      
    
  )
}