import React, { useEffect, useState, useCallback } from 'react';
import api from '../axios/api';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Loader from '../components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Outlet() {
  const [outlets, setOutlets] = useState([]);
  const [cities, setCities] = useState([]);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedCity, setSelectedCity] = useState('Select your city');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchOutlets = useCallback(async (page = 1) => {
    try {
      const postData = selectedCity !== 'Select your city' ? { city: selectedCity } : {};
      const response = await api.post(`${process.env.REACT_APP_BASE_URL}outlet?page=${page}`, postData);
      const newOutlets = response?.data?.data?.data || [];
      
      setOutlets((prevOutlets) => [...prevOutlets, ...newOutlets]);
      setCurrentPage(page);
      setHasMore(newOutlets.length > 0);
    } catch (error) {
      console.error("API error:", error);
    }
  }, [selectedCity]);

  const fetchCities = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_BASE_URL}city-list`);
      setCities(response?.data?.data || []);
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    setOutlets([]);
    setCurrentPage(1);
    fetchOutlets(1);
    fetchCities();
  }, [selectedCity, fetchOutlets]);

  const fetchMoreData = () => {
    fetchOutlets(currentPage + 1);
  };


  const [bannerImage, setBannerImage] = useState("");

  const HomePageData = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_BASE_URL}home`);
      const outletBanner = response?.data?.data?.banners.filter(e => e.type=== "Outlets")
      setBannerImage(outletBanner[0]?.image);
      
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    HomePageData();
  }, []);


  return (
    <div className='wrapper'>
      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
      <div className="about_wrapper outlets_wrapper">
        <img src={`${process.env.REACT_APP_FOR_API_URL}${bannerImage}`} alt="" />  
        <div className="sec_container">
          <div className="about_inner">
            <div className="select_city">
              <span>Select Your City</span> 
              <div className="dropdown">
                <div
                  onClick={() => setIsDropdownActive(!isDropdownActive)}
                  className="dropdown-btn"
                >
                  {selectedCity}
                  <span className={isDropdownActive ? "fas fa-caret-up" : "fas fa-caret-down"} />
                </div>
                <div
                  className="dropdown-content"
                  style={{ display: isDropdownActive ? "block" : "none" }}
                >
                  {cities.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedCity(item.city);
                        setIsDropdownActive(false);
                      }}
                      className="item"
                    >
                      {item.city}
                    </div>
                  ))}
                </div>
              </div>
            </div>
  
            <div className="outlets">
              <InfiniteScroll
                dataLength={outlets.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Loader />}
                // endMessage={
                //   <p style={{ textAlign: 'center' }}>
                //     <b>No more outlets to display</b>
                //   </p>
                // }
              >
                {outlets.map((item, id) => (
                  <div className='outlets_inner' key={id}>
                    <div className='name'>{item.name}</div>
                    <div className='address'>
                      {item.address1} {item.address2} {item.address3} - {item.pincode}
                    </div>
                    <div className='phone'>
                      {item.phone1} {item.phone2 && `- ${item.phone2}`} {item.mobile &&` -  ${item.mobile}`}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
      
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  );
}
