import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";

export default function Banner({ homeApiData }) {

  var settings = {
    dots: true,
    infinite:false,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true
        }
      },
    ]
  };

  console.log(homeApiData?.banners, "homeApiData?.banners")
  return (
    <>
      <Slider {...settings}>
      {homeApiData?.banners
  ?.filter(item => item?.type === null)  // Filter banners with type null
  ?.map((item, id) => (
    <div key={id}>
      <div className="banner_inner">
        <div className="banner_left">
          <span>{item?.tag}</span>
          <h1>{item?.title}</h1>
          <Link to="/categories">All Products</Link>
        </div>
        <div className="banner_right">
          <img src={`${process.env.REACT_APP_FOR_API_URL}${item?.image}`} alt="" />
        </div>
      </div>
    </div>
  ))
}

      </Slider>
    </>

  )
}
